import React, { useEffect } from 'react'
import styled from 'styled-components'
import App from 'App'
import { colors, space } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Sponsors from 'components/Sponsors'
import Container from 'components/Container'
import Footer from 'components/Footer'
import ExternalLink from 'components/Link'
import ImageText from 'routes/About/ImageText'
import bgImage from 'static/images/bg_agency.jpg'
import agency1 from 'static/svg/agency_1.svg'
import agency2 from 'static/svg/agency_2.svg'
import agency3 from 'static/svg/agency_3.svg'
import agency4 from 'static/svg/agency_4.svg'
import badgeLogo from 'static/images/badge.png'
import { AGENCY_VISTA_REVIEWS } from 'consts'
import CarouselReview from 'components/CarouselReview'

const IMAGE_TEXT_CONTENT_FIRST_PART = [
  {
    title: 'Rank at the Top for What Your Agency Actually Crushes It At',
    body:
      'Say goodbye to PPC bidding wars and battling out with agencies that use blackhat SEO tactics to list above your agency on search engines.',
    image: agency1,
  },
  {
    title: 'Generate New Leads on Autopilot',
    body:
      'List which industries, budgets, and business size your agency prefers to work with, and let the right businesses come to you',
    image: agency2,
  },
]

const IMAGE_TEXT_CONTENT_SECOND_PART = [
  {
    title: 'Win Clients Without the Tacky Sales Work',
    body:
      'No more cheesy in-person meetings, repurposed pitchdecks, and rehearsed spiels. Let your agency’s work and experience take centerstage.',
    image: agency3,
  },
  {
    title: 'Verification Is a Win-Win',
    body:
      'Through verified agency profiles, we ensure that businesses searching for agencies can confirm those agencies have the exact experience and success they claim to have. Verification helps agencies rank higher in search results, which means more exposure to those businesses looking to find their dream agency.',
    image: badgeLogo,
  },
  {
    title: 'Showcase Your Top Social Accounts and 5-Star Reviews',
    body:
      'Agency Vista allows you to connect the social media profiles you manage and easily display off all the work you’ve done in a beautifully-organized social portfolio and review feed.',
    image: agency4,
  },
]

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
`

const PostWrapper = styled(Box)`
  iframe {
    min-width: inherit !important;
  }
`

const ButtonLink = styled(Button.Reversed)`
  display: inline-flex;
  text-decoration: none;
`

const Agencies = () => {
  const isSSR = typeof window === 'undefined'
  useEffect(() => {
    if (!isSSR && window.instgrm) {
      window.instgrm.Embeds.process()
    }
  })

  return (
    <App>
      <SEO
        title="Agencies"
        description="Agency Vista helps agencies grow their client portfolio with clients that are nothing less than the perfect fit"
        path="/agencies/"
        image="/img/scale_your_social_agency.jpg"
      />
      <Hero>
        <BgImg src={bgImage} />
        <H1 color="white">Scale Your Agency with Awesome Clients</H1>
        <Box mt="m" maxWidth="600px">
          <StyledH3 color="white">
            Agency Vista helps marketing agencies grow their business with clients that are nothing less than a perfect
            fit
          </StyledH3>
        </Box>
        <Box mt="m">
          <Button.Primary as={ExternalLink} href="/explainer-video/what-is-agency-vista">
            <Text>Watch an Overview Video</Text>
          </Button.Primary>
        </Box>
      </Hero>
      {/* <Sponsors /> */}
      <Container maxWidth="1080px">
        <Box mt="xxl">
          {IMAGE_TEXT_CONTENT_FIRST_PART.map((content, index) => (
            <Box key={content.title} mb="xxl">
              <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
            </Box>
          ))}
        </Box>
      </Container>
      <Box my="xl">
        <Box mt="xxl">
          <CarouselReview reviews={AGENCY_VISTA_REVIEWS} />
        </Box>
      </Box>
      <Container maxWidth="1080px">
        <Box mt="xxl">
          {IMAGE_TEXT_CONTENT_SECOND_PART.map((content, index) => (
            <Box key={content.title} mb="xxl">
              <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
            </Box>
          ))}
        </Box>
      </Container>
      <Box mb="xl" mt={{ mobile: 'xl', tablet: '300px' }} backgroundColor={colors.primary}>
        <Container maxWidth="1080px">
          <Wrapper
            pr={{ mobile: 'xxs', tablet: 'm' }}
            justifyContent={{ mobile: 'center', tablet: 'space-between' }}
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            alignItems="center"
          >
            <Box mb={{ mobile: 'l', tablet: 'xxl' }} mt={{ mobile: 'l', tablet: 'xl' }} maxWidth="420px">
              <H1 color="white">Get Started Today</H1>
              <Box my="m">
                <Text fontSize="l" color="white" opacity={0.8}>
                  Grow your agency with the #1 marketplace for social media agencies
                </Text>
              </Box>
              <ButtonLink as="a" href="/signup" isInverted>
                <Text fontWeight="medium">Get Started</Text>
              </ButtonLink>
            </Box>
            <PostWrapper position={{ tablet: 'absolute' }} bottom="0" right={space.m} width={{ desktop: '465px' }}>
              <blockquote
                className="instagram-media"
                data-instgrm-captioned
                data-instgrm-permalink="https://www.instagram.com/p/B8b0rJRHpOM/?utm_source=ig_embed&amp;utm_campaign=loading"
                data-instgrm-version="12"
                style={{
                  background: '#ffffff',
                  border: 0,
                  borderRadius: '3px',
                  boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                  margin: '1px',
                  maxWidth: '540px',
                  minWidth: '326px',
                  padding: 0,
                  width: 'calc(100% - 2px)',
                }}
              />
            </PostWrapper>
          </Wrapper>
        </Container>
      </Box>
      <Footer />
    </App>
  )
}

export default Agencies
