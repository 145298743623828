import React from 'react'
import styled from 'styled-components'
import { space } from 'theme'
import { Flex } from 'components/Layout'
import facebookBw from 'static/images/facebook_bw.png'
import googleBw from 'static/images/google_bw.png'
import mitBw from 'static/images/mit_bw.png'
import netflixBw from 'static/images/netflix_bw.png'
import intuitBw from 'static/images/intuit_bw.png'
import paypalBw from 'static/images/paypal_bw.png'
import pandgBw from 'static/images/p&g_bw.png'

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0px 0px 40px rgba(39, 40, 49, 0.1);
`

const StyledImg = styled.img`
  margin-left: ${space.l};
  margin-right: ${space.l};
`

const Sponsors = () => (
  <StyledFlex>
    <StyledImg src={facebookBw} />
    <StyledImg src={googleBw} />
    <StyledImg src={mitBw} />
    <StyledImg src={netflixBw} />
    <StyledImg src={intuitBw} />
    <StyledImg src={paypalBw} />
    <StyledImg src={pandgBw} />
  </StyledFlex>
)

export default Sponsors
